@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "NGReg";
    src: url("../public/font/NiveauGroteskRegular.eot");
    src: url("../public/font/NiveauGroteskRegular.woff");
    src: url("../public/font/NiveauGroteskRegular.woff2");
}

@font-face {
    font-family: "NGBold";
    src: url("../public/font/NiveauGroteskBold.eot");
    src: url("../public/font/NiveauGroteskBold.woff");
    src: url("../public/font/NiveauGroteskBold.woff2");
}
